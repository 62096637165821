import { Button } from "@mui/material";
import { Download } from "@mui/icons-material";

export const ExportToExcel = ({ fileName, data }) => {
    const handleExport = async () => {
        if (!data[0]) return;

        // Create CSV header
        const headers = Object.keys(data[0]);
        const csvRows = [];
    
        // Add header row
        csvRows.push(headers.join(','));
    
        // Add data rows
        data.forEach((row) => {
            const values = headers.map((header) => row[header]);
            csvRows.push(values.join(','));
        });
    
        // Create CSV string
        const csvContent = csvRows.join('\n');
    
        // Create a Blob and generate a link for downloading
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        
        // Create a URL for the Blob and set it as the href
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.csv`;
    
        // Trigger a click event to start the download
        link.click();
    };

    return (
        <Button
            sx={{
                fontSize: "12px",
                textTransform: "none",
                color: "grey.800",
                padding: 0,
                margin: 0,
            }}
            onClick={handleExport}
        >
            <Download sx={{ fontSize: "20px" }} />
            Export
        </Button>
    )
};