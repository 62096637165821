import React, { useState, useEffect, useMemo } from "react";
import { Info, Check, Close, CalendarMonth } from "@mui/icons-material";
import { SellerLink } from "../components/SellerLink";
import { ExportToExcel } from "../components/ExportToExcel";
import { getLastUpdate, safeParseFloat } from "../utils";
import {
    Box,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    FormLabel,
    TablePagination
} from "@mui/material";
import { safeParseInt } from "../utils";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const styles = {
    cellTitle: {
        fontSize: "12px",
        whiteSpace: "nowrap",
        minWidth: "max-content",
        verticalAlign: "top",
        textAlign: "left",
        padding: "2px",
        paddingX: "10px",
        borderLeft: "1px solid lightgray"
    },
    cellValue: {
        fontSize: "12px",
        verticalAlign: "top",
        textAlign: "center",
        padding: "10px",
        whiteSpace: "nowrap",
        minWidth: "max-content",
    },
    tableHeader: {
        borderLeft: "none",
        borderBottom: "2px solid pink",
        boxShadow: '0px 4px 6px -2px pink'
    },
    tableContainer: {
        marginTop: "40px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "12px",
            height: "12px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "lightgray",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A9A9A9",
            borderRadius: "10px",
        },
    },
    info: {
        fontSize: "12px",
        textTransform: "none",
        color: "grey.800",
        padding: 0,
        margin: 0,
    }
};

const charts = [
    { // price history
        formatValue: (value) => `$${value}`,
        tooltipValue: "$",
        title: "Price",
        color: "orange",
        key: "price"
    },
    { // stock history
        title: "Stock",
        color: "#3CA4D7",
        key: "stock"
    }
];

const filtersData = {
    shipping: {
        title: "Fulfillment",
        options: [
            { title: "WFS Only", state: false },
            { title: "SF Only", state: false },
        ]
    },
    additional: {
        title: "Additional Options",
        options: [
            { title: "Include historical offers", state: true }
        ]
    }
};

const graphOptions = ["30", "60", "90", "180", "365", "All"];

const getChartOptions = (chartData, data) => {
    const seriesData = {
        name: chartData.title,
        data: data.map(item => ({
            x: new Date(item.date).getTime(),
            y: (item[chartData.key] < 0 || (item[chartData.key] === 0 && !chartData.allowZero)) ? null : item[chartData.key],
        })),
        color: chartData?.color ?? "lightgray",
        type: "area",
        step: "after",
        fillOpacity: chartData.opacity ?? 0,
        yAxis: chartData.axis === "right" ? 1 : 0,
        marker: { enabled: false },
        lineWidth: 1,
    };

    return {
        chart: {
            height: 100,
            width: 200,
            events: {
                load: function () {
                    const chart = this;

                    chart.container.addEventListener("mousemove", (e) => {
                        const point = chart.pointer.normalize(e);
                        const pointX = chart.xAxis[0].toValue(point.chartX);

                        Highcharts.charts.forEach(otherChart => {
                            if (otherChart && otherChart !== chart && otherChart?.series && otherChart?.series.length > 0 && otherChart?.series[0].name === chartData.title) {
                                const points = otherChart?.series.reduce((acc, series) => {
                                    const matchingPoint = series.data.find(p =>
                                        Math.abs(p.x - pointX) < 30 * 60 * 1000 // time points within 30 minutes
                                    );
                                    return matchingPoint ? [...acc, matchingPoint] : acc;
                                }, []) || [];

                                if (points && points.length > 0) {
                                    otherChart.tooltip.refresh(points);
                                }
                            }
                        });
                    });
                },
            },
        },
        legend: {
            enabled: false,
        },
        title: { text: null },
        credits: { enabled: false },
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                day: "%Y/%m/%d"
            }
        },
        yAxis: [{
            title: { text: null },
            labels: {
                style: { fontSize: "80%" },
                formatter: function () {
                    return chartData.formatValue ? chartData.formatValue(this.value) : this.value;
                }
            }
        }],
        tooltip: {
            enabled: true,
            shared: true,
            useHTML: true,
            backgroundColor: 'transparent', // <-- Make background transparent
            borderWidth: 0,                 // Optional: remove border
            shadow: false,                  // Optional: remove shadow
            formatter: function () {
                let html = `<div style="font-family: sans-serif; font-size: 12px;">`;
                html += `<b>${Highcharts.dateFormat("%Y/%m/%d %H:%M", this.x)}</b><br/>`;
                html += `<span style="color:${chartData.color}">${chartData.title}: <strong>${chartData?.tooltipValue || ""}${this.point.y}</strong></span><br/>`;
                html += "</div>";
                return html;
            }
        },
        series: [seriesData],
    };
};

export const Offers = (props) => {
    const allSellers = useMemo(() => extractSellersData(), [props.productHistory]);

    const [selectedRange, setSelectedRange] = useState("All");
    const [filters, setFilters] = useState(filtersData);
    const [sellersInfo, setSellersInfo] = useState(filterSellers());

    const [page, setPage] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [paginatedSellers, setPaginatedSellers] = useState(sellersInfo.slice(page * 5, page * 5 + 5))

    const columnsData = [
        ...(!props.isHalfData ? [{ label: "Price", sortable: true, key: "price" }] : []),
        { label: "Price History", sortable: false },
        ...(!props.isHalfData ? [{ label: "Stock", sortable: true, key: "stock" }] : []),
        { label: "Sold", sortable: true, key: "sold" },
        { label: "Sold 30 days", sortable: true, key: "sold30Days" },
        { label: "Stock History", sortable: false },
        { label: "WFS", sortable: false },
        { label: "Name", sortable: false },
        ...(!props.isHalfData ? [{ label: "Last seen", sortable: false }] : []),
        ...(!props.isHalfData ? [{ label: "First seen", sortable: false }] : []),
    ];

    useEffect(() => {
        const filteredSellers = filterSellers();
        setSellersInfo(filteredSellers);
        setPage(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, selectedRange]);

    useEffect(() => {
        setPaginatedSellers(sellersInfo.slice(page * 5, page * 5 + 5));
    }, [sellersInfo, page]);

    function extractSellersData() {
        const history = props.productHistory.map(data => ({
            date: new Date(data.date),
            offers: JSON.parse(data?.offers)
        })).sort((a, b) => a.date - b.date);

        const sellers = history.reduce((acc, record, index, array) => {
            record.offers.forEach(seller => {
                if (!acc[seller.sellerName]) {
                    acc[seller.sellerName] = {
                        sellerName: seller.sellerName,
                        sellerId: seller.sellerId,
                        sold: 0,
                        sold30Days: 0,
                        firstSeen: record.date,
                        lastSeen: record.date,
                        data: [],
                    };
                }

                acc[seller.sellerName].data.push({
                    price: safeParseFloat(seller.price),
                    stock: safeParseInt(seller.stock),
                    date: record.date
                });

                acc[seller.sellerName].wfs = seller.wfs;
                acc[seller.sellerName].firstSeen = record.date;

                const prevRecord = array[index - 1]?.offers.find(s => s.sellerName === seller.sellerName);
                if (prevRecord) {
                    const stockDifference = prevRecord.stock - seller.stock;
                    if (stockDifference > 0) {
                        acc[seller.sellerName].sold += stockDifference;

                        const daysAgo = (new Date() - record.date) / (1000 * 60 * 60 * 24); // 30 days
                        if (daysAgo <= 30) {
                            acc[seller.sellerName].sold30Days += stockDifference;
                        }
                    }
                }
            });
            return acc;
        }, {});

        return sellers;
    }

    function filterSellers() {
        let sellers = allSellers;

        if (!filters.additional.options[0].state) {
            const offers = JSON.parse(props.productHistory[props.productHistory.length - 1]?.offers);
            const lastOffers = offers || [];
            const sellerNames = lastOffers.map(seller => seller.sellerName);

            sellers = Object.values(sellers).filter(seller => sellerNames.includes(seller.sellerName));
        }

        if (filters.shipping.options[0].state) {
            sellers = Object.values(sellers).filter(seller => seller.wfs);
        }
        if (filters.shipping.options[1].state) {
            sellers = Object.values(sellers).filter(seller => !seller.wfs);
        }

        return filterDataByRange(Object.values(sellers), selectedRange);
    }

    const handleCheckboxChange = (section, index) => {
        setFilters((prevFilters) => {
            const updatedOptions = [...prevFilters[section].options];
            updatedOptions[index].state = !updatedOptions[index].state;
            return { ...prevFilters, [section]: { ...prevFilters[section], options: updatedOptions } };
        });
    };

    const handleSort = (key) => {
        let direction = "asc";

        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                direction = "desc";
            } else if (sortConfig.direction === "desc") {
                direction = null;
                key = null;
            }
        }

        setSortConfig({ key, direction });

        const sellers = filterSellers();

        if (direction === null) {
            setSellersInfo(sellers);
        } else {
            const sorted = [...sellers].sort((a, b) => {
                if (!key) return 0;

                const aLastData = a.data[a.data.length - 1];
                const bLastData = b.data[b.data.length - 1];

                switch (key) {
                    case "price":
                    case "stock":
                        return direction === "asc" ?
                            bLastData[key] - aLastData[key] :
                            aLastData[key] - bLastData[key];
                    case "sold":
                    case "sold30days":
                        return direction === "asc" ?
                            b[key] - a[key] :
                            a[key] - b[key];
                    default:
                        return 0;
                }
            });
            setSellersInfo(sorted);
        }
    };

    function getSafeTableValue(value, isFloat = false) {
        const safeValue = isFloat ? safeParseFloat(value) : safeParseInt(value);
        if (typeof safeValue !== 'number' || isNaN(safeValue) || safeValue < 0) return "N/A";
        return safeValue;
    }

    function filterDataByRange(data, range) {
        const now = new Date();

        const filteredData = data.map(seller => ({
            ...seller,
            data: seller.data.filter(item => {
                const itemDate = new Date(item.date);
                const diffInDays = Math.floor((now - itemDate) / (1000 * 60 * 60 * 24));

                if (range !== "All") {
                    return diffInDays <= +range;
                }
                return true;
            })
        })).filter(seller => seller.data.length > 0);

        return filteredData;
    };

    function formatExportData() {
        return sellersInfo.map(seller => ({
            "Price": seller.data.length > 0 ? `$${seller.data[seller.data.length - 1].price.toFixed(2)}` : "N/A",
            "Stock": seller.data.length > 0 ? seller.data[seller.data.length - 1].stock : "N/A",
            "Sold": seller.sold,
            "Sold 30 days": seller.sold30Days,
            "WFS": seller.wfs ? "Yes" : "No",
            "Name": seller.sellerName,
            "First seen": seller.firstSeen.toLocaleDateString(),
            "Last seen": seller.lastSeen.toLocaleDateString(),
        }))
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px", gap: "8px" }}>
                <ExportToExcel fileName="Offers" data={formatExportData()} />
                <Box sx={{ ...styles.info, display: "flex", alignItems: "center", gap: "2px", textAlign: "center" }}>
                    <Info sx={{ fontSize: "18px" }} />
                    <span style={{ display: "flex", alignItems: "cetner", color: "#757575" }}>Number of results: {sellersInfo.length}</span>
                </Box>
                <Box sx={{ ...styles.info, display: "flex", alignItems: "center", gap: "2px", textAlign: "center" }}>
                    <CalendarMonth sx={{ fontSize: "18px" }} />
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        flexWrap: "wrap"
                    }}>
                        <span style={{ fontWeight: "semibold" }}>Graph range in days:</span>
                        {graphOptions.map((option) => (
                            <div
                                key={option}
                                onClick={() => setSelectedRange(option)}
                                style={{
                                    color: selectedRange === option ? "black" : "darkgray",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
                {Object.keys(filters).map((section) => (
                    <Box key={section} sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                        <FormLabel component="legend" sx={{ color: "black", fontWeight: "bold", fontSize: "12px" }}>
                            {filters[section].title}
                        </FormLabel>
                        <FormGroup row>
                            {filters[section].options.map((filter, index) => (
                                <FormControlLabel
                                    key={`${section}-${index}`}
                                    control={
                                        <Checkbox
                                            checked={filter.state}
                                            onChange={() => handleCheckboxChange(section, index)}
                                            size="small"
                                        />
                                    }
                                    label={filter.title}
                                    sx={{
                                        '& .MuiFormControlLabel-label': {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            ))}
                        </FormGroup>
                    </Box>
                ))}
            </Box>
            <TableContainer component={Paper} sx={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...styles.cellTitle, borderLeft: "none" }} colSpan={3}></TableCell>
                            {!props.isHalfData && <TableCell sx={{ ...styles.cellTitle, ...styles.tableHeader }} colSpan={3}>Stock</TableCell>}
                            <TableCell sx={{ ...styles.cellTitle, borderLeft: "none" }} colSpan={!props.isHalfData ? 1 : 2}></TableCell>
                            <TableCell sx={{ ...styles.cellTitle, ...styles.tableHeader }} colSpan={!props.isHalfData ? 3 : 2}>Seller</TableCell>
                        </TableRow>
                        <TableRow>
                            {columnsData.map((col, index) => (
                                <TableCell key={`${col.key}-${index}`} sx={styles.cellTitle}>
                                    {col.sortable ? (
                                        <TableSortLabel
                                            active={sortConfig.key === col.key}
                                            direction={sortConfig.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort(col.key)}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    ) : (
                                        col.label
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedSellers.map((row, index) => (
                            <TableRow key={index} sx={{ backgroundColor: row.sellerId === props.productHistory[props.productHistory.length - 1]?.current_seller?.sellerId ? "rgba(255, 182, 193, 0.1)" : "" }}>
                                {!props.isHalfData && <TableCell sx={{ ...styles.cellValue, textAlign: "right" }}>
                                    {row.data.length > 0 ? `$${getSafeTableValue(row.data[row.data.length - 1].price, true).toFixed(2)}` : "N/A"}
                                </TableCell>}
                                <TableCell sx={styles.cellValue}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={getChartOptions(charts[0], row.data)}
                                    />
                                </TableCell>
                                {!props.isHalfData && <TableCell sx={{ ...styles.cellValue, textAlign: "right" }}>{row.data.length > 0 ? getSafeTableValue(row.data[row.data.length - 1].stock) : "N/A"}</TableCell>}
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right" }}>~{row.sold}</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right" }}>{row.sold30Days}</TableCell>
                                <TableCell sx={styles.cellValue}>
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={getChartOptions(charts[1], row.data)}
                                    />
                                </TableCell>
                                <TableCell sx={styles.cellValue}>{row.wfs ? <Check /> : <Close />}</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "left" }}>
                                    <SellerLink sellerName={row.sellerName} sellerId={row.sellerId} />
                                </TableCell>
                                {!props.isHalfData && <TableCell sx={styles.cellValue}>{row.firstSeen ? getLastUpdate(row.firstSeen) : "N/A"}</TableCell>}
                                {!props.isHalfData && <TableCell sx={{ ...styles.cellValue }}>{row.lastSeen ? getLastUpdate(row.lastSeen) : "N/A"}</TableCell>}
                            </TableRow>
                        ))}
                        {props.isHalfData && <TableRow style={{ border: '2px solid rgba(100, 162, 27, 0.2)', }}>
                            <TableCell colSpan={12} style={{ textAlign: "center" }}>More data will be shown soon.</TableCell></TableRow>}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={sellersInfo.length}
                    rowsPerPage={5}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                />
            </TableContainer>
        </Box >
    );
}