import { useEffect, useState, useMemo } from "react";
import { CalendarMonth } from "@mui/icons-material";
import { Box } from "@mui/material";
import { safeParseInt, safeParseFloat } from "../utils";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const graphOptions = [
    { label: "Day", value: 1 },
    { label: "Week", value: 7 },
    { label: "Month", value: 30 },
    { label: "3 Months", value: 90 },
    { label: "All", value: 0 }
];

const getChartOptions = (chart, isHalf) => { // flow changes in comment
    const seriesData = Object.entries(chart.chartCategories).map(([key, value]) => ({
        name: value.title,
        data: chart.data.map((item, index) => {
            let isIsolated = false;
            try {
                if (key.includes("stock_")) {
                    const isFirst = index === 0;  // Is it the first item in the series?
                    const isLast = index === chart.data.length - 1;  // Is it the last item in the series?
                    const prevKeys = !isFirst && chart.data[index - 1] && Object.keys(chart.data[index - 1])
                    const currKeys = item && Object.keys(item)
                    const nextKeys = !isLast && chart.data[index + 1] && Object.keys(chart.data[index + 1])
                    isIsolated = currKeys && prevKeys && nextKeys && currKeys.some(key =>
                        !prevKeys.includes(key) || !nextKeys.includes(key)
                    );
                }
            } catch { }

            return {
                x: new Date(item.date).getTime(),
                y: (item[key] < 0 || (item[key] === 0 && !value.allowZero)) ? null : item[key],
                buyBoxWinner: item.buyBoxWinner,
                marker: {
                    enabled: isIsolated,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            }
        }),
        color: value?.color ?? "lightgray",
        type: Object.keys(chart.chartCategories).includes("sales") ? "column" : "area",
        step: "after",
        fillOpacity: value.opacity ?? 0,
        yAxis: value.axis === "right" ? 1 : 0,
        // marker: {
        //     enabled: true,
        //     // states: {
        //     //     hover: {
        //     //         enabled: false
        //     //     }
        //     // }
        // },
        lineWidth: 1,
        // boostThreshold: 1,
        // findNearestPointBy: "x",
        // stickyTracking: false,
        // interpolation: 'monotone',
        // connectNulls: true,
    }));

    return {
        chart: {
            height: 285,
            marginRight: 200,
            zoomType: "x",
            zooming: {
                resetButton: {
                    theme: {
                        style: {
                            display: "none",
                        },
                    }
                },
            },
            events: {
                load: function () {
                    const chart = this;

                    chart.container.addEventListener("dblclick", () => {
                        chart.xAxis[0].setExtremes(null, null);
                    });
                    function handleMove(e) {
                        const normalizedEvent = chart.pointer.normalize(e);
                        const pointX = chart.xAxis[0].toValue(normalizedEvent.chartX);
                        const formattedDate = Highcharts.dateFormat("%Y/%m/%d %H:%M", pointX);

                        if (pointX > Date.now()) { return; }

                        const dateElements = document.querySelectorAll('.date-highcharts-tooltip b');
                        dateElements.forEach((dateElement) => {
                            dateElement.textContent = formattedDate;
                        });

                        const leftPoints = chart.series.map(series => {
                            const data = series.data;
                            for (let i = data.length - 1; i >= 0; i--) {
                                if (data[i].x <= pointX) return data[i];
                            }
                            return null;
                        }).filter(p => p != null);

                        if (leftPoints.length) {
                            chart.tooltip.refresh(leftPoints);
                        }

                        const tooltip = chart.tooltip.getLabel();
                        const mouseX = normalizedEvent.chartX + 10;
                        const mouseY = normalizedEvent.chartY - 20;

                        if (mouseX + tooltip.width > chart.chartWidth) {
                            tooltip.attr({ x: mouseX - tooltip.width - 20 });
                        } else {
                            tooltip.attr({ x: mouseX });
                        }

                        if (mouseY + tooltip.height > chart.chartHeight) {
                            tooltip.attr({ y: mouseY - tooltip.height - 20 });
                        } else {
                            tooltip.attr({ y: mouseY });
                        }


                        // Highcharts.charts.forEach(otherChart => {
                        //     if (otherChart && otherChart !== chart && otherChart?.series) {
                        //         const points = otherChart?.series.reduce((acc, series) => {
                        //             const matchingPoint = series.data.find(p =>
                        //                 Math.abs(p.x - pointX) < 30 * 60 * 1000 // time points within 30 minutes
                        //             );
                        //             return matchingPoint ? [...acc, matchingPoint] : acc;
                        //         }, []) || [];

                        //         if (points && points.length > 0) {
                        //             otherChart.tooltip.refresh(points);
                        //         }
                        //     }
                        // });
                    }
                    chart.container.addEventListener("mousemove", handleMove);
                    chart.container.addEventListener("touchmove", (e) => {
                        e.preventDefault();
                        handleMove(e);
                    }, { passive: false });

                },
            },
        },
        title: { text: null },
        credits: { enabled: false },
        xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
                day: "%m/%d",
                month: "%m/%d",
                year: "%Y/%m/%d"
            },
            crosshair: {
                snap: false,
                width: 1,
                color: 'rgba(102, 102, 102, 0.8)',
                dashStyle: 'Solid',
                zIndex: 2,
                label: {
                    enabled: true,
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    padding: 8,
                    shape: 'rect'
                }
            },
            events: {
                afterSetExtremes: function (e) {
                    Highcharts.charts.forEach(otherChart => {
                        if (otherChart && otherChart !== this.chart) {
                            otherChart.xAxis[0].setExtremes(e.min, e.max);
                        }
                    });
                }
            }
        },
        yAxis: [{
            title: { text: null },
            labels: {
                style: { fontSize: "80%" },
                formatter: function () {
                    return chart.formatValue ? chart.formatValue(this.value) : this.value;
                }
            },
            crosshair: {
                snap: false,
                width: 1,
                color: 'rgba(102, 102, 102, 0.4)',
                dashStyle: 'Solid'
            }
        },
        {
            title: { text: null },
            opposite: true,
            labels: {
                style: { fontSize: "80%" }
            }
        }],
        legend: {
            align: "right",
            verticalAlign: "middle",
            layout: "vertical",
            itemStyle: { fontSize: "15px" },
            itemMarginBottom: 10,
        },
        tooltip: {
            shared: true,
            useHTML: true,
            followPointer: true,
            snap: false,
            positioner: function (labelWidth, labelHeight, point) {
                const tooltipX = point.plotX + this.chart.plotLeft + 10;
                const tooltipY = point.plotY + this.chart.plotTop - 20;

                if (tooltipX + labelWidth > this.chart.chartWidth) {
                    return {
                        x: tooltipX - labelWidth - 20,
                        y: tooltipY
                    };
                }

                return {
                    x: tooltipX,
                    y: tooltipY
                };
            },
            formatter: function () {
                let html = `<div style="font-family: sans-serif; font-size: 16px;>"`;
                html += `<span class="date-highcharts-tooltip"><b>${Highcharts.dateFormat("%Y/%m/%d %H:%M", this.x)}</b><br/></span>`;

                this.points.forEach(point => {
                    const series = point.series;
                    const category = Object.entries(chart.chartCategories)
                        .find(([_, value]) => value.title === series.name);

                    if (category) {
                        const categoryValue = category[1];
                        const icon = categoryValue.icon || "";
                        const tooltipValue = categoryValue.tooltipValue || "";
                        if (point.y != undefined) {
                            html += `<span style="color:${series.color}">${series.name}${icon}: <strong>${tooltipValue}${point.y}</strong></span><br/>`;
                        }
                    }
                });

                const buyBoxPoint = this.points.find(point => point.buyBoxWinner);
                if (buyBoxPoint) {
                    html += `<span style="color:deeppink">Buy Box Winner: <strong>${buyBoxPoint.buyBoxWinner}</strong></span>`;
                }

                html += "</div>";
                return html;
            }
        },
        series: seriesData,
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 1200
                },
                chartOptions: {
                    chart: {
                        marginRight: 0
                    },
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom',
                        itemStyle: { fontSize: '12px' },
                        itemMarginBottom: 5
                    }
                }
            }]
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    },
                    hover: {
                        enabled: true,
                        lineWidth: 2
                    }
                },
                point: {
                    events: {
                        mouseOver: function () {
                            this.series.chart.series.forEach(function (s) {
                                s.points.forEach(function (p) {
                                    if (p.x === this.x) {
                                        p.setState('hover');
                                    }
                                }, this);
                            }, this);
                        },
                        mouseOut: function () {
                            this.series.chart.series.forEach(function (s) {
                                s.points.forEach(function (p) {
                                    p.setState('');
                                });
                            });
                        }
                    }
                }
            },
            column: {
                pointWidth: isHalf ? 30 : 5, // Adjust this to make bars wider globally for 'sales'
                groupPadding: isHalf ? 0.1 : 0, // Adjust space between columns
            }
        },
    };
};

export const WalmartPriceHistory = (props) => {
    const priceHistoryData = [
        {
            data: [],
            formatValue: (value) => `$${value}`,
            chartCategories: !props.isHalfData ? {
                new: { title: "Lowest Price", state: true, color: "#8C8CFF", tooltipValue: "$" },
                wfs: { title: "WFS Price", state: true, color: "rgb(170,50,0)", tooltipValue: "$" },
                sf: { title: "SF Price", state: true, color: "darkcyan", tooltipValue: "$" },
                walmart: { title: "Walmart Price", state: true, color: "darkorange", tooltipValue: "$", opacity: 0.2 },
                buyBox: { title: "Buy Box Price", state: true, color: "deeppink", tooltipValue: "$" },
            } : {
                new: { title: "Lowest Price", state: true, color: "#8C8CFF", tooltipValue: "$" },
                buyBox: { title: "Buy Box Price", state: true, color: "deeppink", tooltipValue: "$" },
            },
        },
        {
            data: [],
            chartCategories: !props.isHalfData ? {
                sfStock: { title: "SF Stock", color: "darkcyan" },
                wfsStock: { title: "WFS Stock", color: "rgb(170,50,0)" },
                walmartStock: { title: "Walmart Stock", color: "darkorange", opacity: 0.2 },
                stock: { title: "Total Stock", color: "deeppink" },
            } : {
                stock: { title: "Buy Box Stock", color: "deeppink" }
            },
        },
        {
            data: [],
            rightAxisColor: "#00CCCC",
            chartCategories: {
                rating: { title: "Rating", color: "blue" },
                reviewCount: { title: "Review Count", color: "cyan", axis: "right" },
                offerCount: { title: "Offer Count", color: "purple" },
            },
        },
        {
            data: [],
            chartCategories: {
                badgeBestSeller: { title: "Best Seller", color: "blue", axis: "right" },
                badgePopularPick: { title: "Popular Pick", color: "cyan", axis: "right" },
                badgeAmountInCart: { title: "Amount In Cart", color: "purple" },
                badgeAmountSold: { title: "Amount Sold", color: "green" },
            },
        },
        {
            data: [],
            chartCategories: {
                sales: { title: "Sales", color: "#F6D500" }
            },
        },
    ];

    const allChartsData = useMemo(() => getChartsData(), [props.productHistory]);

    const [chartData, setChartData] = useState(priceHistoryData);
    const [selectedRange, setSelectedRange] = useState(30);

    useEffect(() => {
        if (!allChartsData || !Array.isArray(allChartsData) || allChartsData.length === 0) return;

        Highcharts.charts.forEach(chart => {
            chart?.xAxis[0]?.setExtremes(null, null);
        });

        setChartData(prevData => prevData.map((item, index) => {
            if (props.isHalfData && index == 1) {
                const newChartCategories = ([...new Set(
                    allChartsData[index].flatMap(entry => Object.keys(entry).filter(key => key.startsWith("stock_")))
                )].reduce((acc, winner, index) => {
                    acc[`${winner}`] = {
                        title: `${winner.replace("stock_", "")}'s Stock`,
                        color: `hsl(${index * 30}, 70%, 50%)` // Generate distinct colors
                    };
                    return acc;
                }, {}))

                return {
                    ...item,
                    chartCategories: newChartCategories,
                    data: filterDataByRange(allChartsData[index], selectedRange)
                }
            } else {
                if (index == 4) {
                    const newChartCategories = ([...new Set(
                        allChartsData[index].flatMap(entry => Object.keys(entry)).filter((val) => val !== "date")
                    )].reduce((acc, winner, index) => {
                        acc[`${winner}`] = {
                            title: `${winner.replace("sales", "Total Tracked")}`,
                            color: (props.isHalfData && winner === "sales") ? "transparent" : `hsl(${index * 30}, 70%, 50%)` // Generate distinct colors
                        };
                        return acc;
                    }, {}))

                    return {
                        ...item,
                        chartCategories: newChartCategories,
                        data: filterDataByRange(allChartsData[index], selectedRange)
                    }
                }
                return {
                    ...item,
                    data: filterDataByRange(allChartsData[index], selectedRange)
                }
            }
        }));
    }, [props.productHistory, selectedRange]);

    function calculateStockDrops(prevEntry, entry) {
        let entryStockMap = {};
        let stockDrops = {};

        if (!props.isHalfData) {
            try {
                stockDrops['WFS'] = (entry.stock_wfs && prevEntry.stock_wfs > entry.stock_wfs) ? prevEntry.stock_wfs - entry.stock_wfs : 0
            } catch {
                stockDrops['WFS'] = 0
            }

            try {
                stockDrops['SF'] = (entry.stock_sf && prevEntry.stock_sf > entry.stock_sf) ? prevEntry.stock_sf - entry.stock_sf : 0
            } catch {
                stockDrops['SF'] = 0
            }
        } else {
            // Create a map from prevEntry for quick lookup
            entry.offers.forEach(seller => {
                entryStockMap[`${seller.sellerName} [${seller.wfs ? "WFS" : "SF"}]`] = seller.stock;
            });

            prevEntry.offers.forEach(seller => {
                if (!props.isHalfData || entryStockMap.hasOwnProperty(`${seller.sellerName} [${seller.wfs ? "WFS" : "SF"}]`)) {
                    if (seller.stock > (entryStockMap[`${seller.sellerName} [${seller.wfs ? "WFS" : "SF"}]`] || 0)) {
                        stockDrops[`${seller.sellerName} [${seller.wfs ? "WFS" : "SF"}]`] = seller.stock - (entryStockMap[`${seller.sellerName} [${seller.wfs ? "WFS" : "SF"}]`] || 0);
                    }
                }
            });
        }

        return stockDrops;
    }

    function getChartsData() {
        if (!props.productHistory || !Array.isArray(props.productHistory) || props.productHistory.length === 0) return;

        const newData = Array.from({ length: priceHistoryData.length }).map(_ => []);

        let prevEntry = null;

        for (const [eIndex, entry] of props.productHistory.entries()) {
            const date = entry.date.replaceAll("-", "/").replace("T", " ");

            if (!props.isHalfData) {
                newData[0].push({
                    date,
                    buyBox: safeParseFloat(entry.current_price?.price),
                    new: safeParseFloat(entry.current_price?.price_lowest),
                    wfs: safeParseFloat(entry.current_price?.price_lowest_wfs),
                    sf: safeParseFloat(entry.current_price?.price_lowest_sf),
                    walmart: safeParseFloat(entry.current_price?.price_walmart),
                    buyBoxWinner: entry.current_seller?.seller_display_name ?? ""
                });
            } else {
                newData[0].push({
                    date,
                    buyBox: safeParseFloat(entry.current_price?.price),
                    new: safeParseFloat(entry.current_price?.price_lowest),
                    buyBoxWinner: entry.current_seller?.seller_display_name ?? ""
                });
            }

            if (!props.isHalfData) {
                newData[1].push({
                    date,
                    stock: safeParseInt(entry?.current_stock?.stock),
                    sfStock: safeParseInt(entry?.current_stock?.stock_sf),
                    wfsStock: safeParseInt(entry?.current_stock?.stock_wfs),
                    walmartStock: safeParseInt(entry?.current_stock?.stock_walmart),
                });
            } else {
                newData[1].push({
                    date,
                    [`stock_${entry.current_seller?.seller_display_name ?? ""}`]: safeParseInt(entry?.current_stock?.stock)
                });
            }

            newData[2].push({
                date,
                rating: safeParseFloat(entry.rating),
                reviewCount: safeParseInt(entry.review_count),
                offerCount: safeParseInt(entry.current_offers?.offer_count),
            });

            newData[3].push({
                date,
                badgeBestSeller: safeParseInt(entry.current_badges?.badge_best_seller),
                badgePopularPick: safeParseInt(entry.current_badges?.badge_popular_pick),
                badgeAmountInCart: safeParseInt(entry.current_badges?.badge_x_amount_in_cart),
                badgeAmountSold: safeParseInt(entry.current_badges?.badge_x_amount_sold),
            });

            if (prevEntry) {
                try {
                    const pv = { offers: JSON.parse(prevEntry.offers), stock_wfs: prevEntry.current_stock.stock_wfs, stock_sf: prevEntry.current_stock.stock_sf }
                    const cv = { offers: JSON.parse(entry.offers), stock_wfs: entry.current_stock.stock_wfs, stock_sf: entry.current_stock.stock_sf }
                    let additionalSpaces = [];
                    if (props.isHalfData) {
                        try {
                            const pv_ofrs = pv.offers.map(item => item.sellerName)
                            const cv_ofrs = cv.offers.map(item => item.sellerName)
                            const need2Find = cv_ofrs.filter(seller => !pv_ofrs.includes(seller))
                            if (need2Find) {
                                const lastOfrs = (JSON.parse(props.productHistory.slice(0, eIndex).reverse().find(item =>
                                    item.offers.includes(need2Find[0])
                                ).offers))[0]

                                if (lastOfrs) {
                                    if (cv.offers[0].stock && lastOfrs.stock && (lastOfrs.stock > cv.offers[0].stock)) {
                                        additionalSpaces[`${cv.offers[0].sellerName} [${cv.offers[0].wfs ? "WFS" : "SF"}]`] = lastOfrs.stock - cv.offers[0].stock
                                    }
                                }
                            }
                        } catch { }
                    }
                    newData[4].push({
                        date,
                        sales: Object.values(calculateStockDrops(pv, cv)).reduce((acc, num) => acc + num, 0),
                        ...calculateStockDrops(pv, cv),
                        ...additionalSpaces
                    });
                } catch { }
            }
            prevEntry = entry;
        }
        return newData;
    }

    const filterDataByRange = (data, range) => {
        if (range < 1) return data;

        const now = new Date();
        let startDate = new Date();

        if (range === 0) {
            startDate = new Date(Math.min(...data.map(item => new Date(item.date))));
        } else {
            startDate.setDate(now.getDate() - range);
        }

        const filteredData = data.filter(item => {
            const itemDate = new Date(item.date);
            let diffInDays = Math.floor((now - itemDate) / (1000 * 60 * 60 * 24));

            return diffInDays <= range;
        });
        return filteredData;
    };

    return (
        <Box style={{ width: "98%", display: "flex", flexDirection: "column", gap: "30px", height: "100%" }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                flexWrap: "wrap"
            }}>
                <span style={{ display: "flex", alignItems: "center", fontWeight: "semibold" }}>
                    <CalendarMonth sx={{ fontSize: "18px" }} />
                    Graph range:
                </span>
                {graphOptions.map((option) => (
                    <div
                        key={option.value}
                        onClick={() => setSelectedRange(option.value)}
                        style={{
                            color: selectedRange === option.value ? "black" : "darkgray",
                            cursor: "pointer",
                            fontSize: "12px",
                        }}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
            {chartData.map((chart, index) => (<>
                {index === 4 && <h2 style={{ textAlign: 'left', marginLeft: "2vw" }}>Estimated Sales Chart - Stock Drop Tracker</h2>}
                <HighchartsReact
                    key={index}
                    highcharts={Highcharts}
                    options={getChartOptions(chart, props.isHalfData)}
                />
            </>
            ))}
        </Box >
    );
};