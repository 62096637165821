import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Modal, TextField } from '@mui/material';
import { isUserAuthenticated } from './utils/authService';

// Styled components for search bar
const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function Navbar() {
    const [anchorEl, setAnchorEl] = React.useState(null);  // Menu anchor state
    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);  // Mobile menu state
    const loggedIn = isUserAuthenticated();

    const handleMenuClose = () => {
        setAnchorEl(null);
        setMobileMenuOpen(false);  // Close mobile menu after selection
    };

    const handleMobileMenuToggle = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };

    const queryParams = new URLSearchParams(window.location.search);
    const extN = queryParams.get("extN");

    if (extN) {
        return null
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" style={{ backgroundColor: "#2ECC40" }}>
                <Toolbar>
                    {/* Logo */}
                    <Typography variant="h6" sx={{ flexGrow: 1 }} onClick={() => window.location.href = "/"} style={{ cursor: "pointer" }}>
                        <img src="/histomart_white.png" style={{ width: "15%" }} className="navLogo" />
                    </Typography>

                    {/* Mobile Menu Icon */}
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                        onClick={handleMobileMenuToggle}
                    >
                        <MenuIcon />
                    </IconButton>

                    {/* Pricing Button (visible on larger screens) */}
                    <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Button color="inherit" onClick={() => { window.location.href = "/pricing" }}>Pricing</Button>
                    </Box>

                    <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Button color="inherit" onClick={() => window.open("https://chromewebstore.google.com/detail/histomart/eocaegpgmbgncbmhmigamhlangcipkco")}>Chrome Extension</Button>
                    </Box>

                    {/* Pricing Button (visible on larger screens) */}
                    {loggedIn && <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                        <Button color="inherit" onClick={() => { window.location.href = "https://www.marteroa.com/" }}>W2W Flips</Button>
                    </Box>}

                    {/* Search Bar */}
                    {loggedIn && <Button sx={{ display: { xs: 'none', sm: 'flex' } }} onClick={() => { window.location.href = "/" }} variant="outlined" color="inherit" startIcon={<SearchIcon />}>
                        Search
                    </Button>}

                    {/* Login/Logout Button */}
                    <IconButton
                        size={loggedIn ? "large" : "small"}
                        edge="end"
                        aria-label="account of current user"
                        color="inherit"
                        onClick={() => { window.location.href = "/user" }}
                        style={{
                            border: !loggedIn && "3px solid darkgreen",
                            padding: "10px",
                            borderRadius: !loggedIn && "5px"
                        }}
                    >
                        {loggedIn ? <AccountCircle /> : "Login"}
                    </IconButton>
                </Toolbar>
            </AppBar>

            {/* Mobile Menu */}
            <Menu
                anchorEl={anchorEl}
                open={mobileMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => { window.location.href = "/pricing" }}>Pricing</MenuItem>
                <MenuItem onClick={() => window.open("https://chromewebstore.google.com/detail/histomart/eocaegpgmbgncbmhmigamhlangcipkco")}>Chrome Extension</MenuItem>
                <MenuItem onClick={() => { window.location.href = "https://www.marteroa.com/" }}>W2W Flips</MenuItem>
                <MenuItem onClick={() => { window.location.href = "/" }}>
                    <Button variant="outlined" color="inherit" startIcon={<SearchIcon />}>
                        Search
                    </Button>
                </MenuItem>
            </Menu>
        </Box>
    );
}
