import './App.css';
import KpPage from './kp/kp';
import { AppBar, Toolbar, Paper, Typography, Container, Grid, Button, Box, Card, CardContent, CardMedia, boxClasses, TextField, InputAdornment } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Navbar from './Navbar';
import PricingPage from './Pricing';
import LoginSignUp from './Login/Login';
import { isUserAuthenticated } from './utils/authService';
import UserPage from './UserPage/UserPage';
import { Search } from '@mui/icons-material';
import SearchPage from './kp/SearchPage'


function App() {
  if (window.location.href.includes("kp")) {
    return <KpPage />
  }

  if (window.location.href.includes("pricing")) {
    return <PricingPage />
  }

  if (window.location.href.includes("user")) {
    return isUserAuthenticated() ? <UserPage /> : <LoginSignUp />
  }

  if (window.location.href.includes("search")) {
    return isUserAuthenticated() ? <SearchPage /> : <LoginSignUp />
  }

  return (
    <div className='bg'>
      <Navbar />

      {isUserAuthenticated() ?
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          minHeight="95vh"
          bgcolor="#E8F5E9"
        >
          <TextField
            variant="outlined"
            placeholder="Search by Item ID / UPC / Product Name"
            sx={{
              width: "100%",
              maxWidth: 400,
              bgcolor: "white",
              borderRadius: 1,
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#4CAF50" },
                "&:hover fieldset": { borderColor: "#388E3C" },
                "&.Mui-focused fieldset": { borderColor: "#2E7D32" },
              },
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                window.location.href = `/search?q=${e.target.value}`
              }
            }}
            onBlur={(e) => { if (e.target.value) { window.location.href = `/search?q=${e.target.value}` } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search sx={{ color: "#4CAF50" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button color='success' variant='contained' style={{height: "55px"}}>Go!</Button>
        </Box>
        : <>
          <Container sx={{ paddingTop: 6, textAlign: 'center' }}>
            <Typography variant="h3" sx={{ color: "#2ECC40", fontWeight: 'bold', marginBottom: 3 }}>
              Historical Data for Walmart
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 4 }}>
              Discover trends, analyze insights, and make informed decisions with historical data.
            </Typography>
          </Container>

          <Container sx={{ paddingY: 3 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Carousel thumbWidth={"19%"} infiniteLoop={true} autoPlay={true} interval={4000}>
                  <div>
                    <img src="s1.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s2.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s3.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s4.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s5.png" alt="Screenshot" className="carImg" />
                  </div>
                </Carousel>
              </Grid>
            </Grid>
          </Container>

          <Container sx={{ paddingY: 6 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 4 }}>
              Featured Data
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>History Charts</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Price History, Stock History, Offer Count History and Badges History.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Product Data</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Key information and statistics, OOS status, average pricing, lowest pricing, and more.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Offers Data</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Offers table, price history, stock history, and Buy Box winning statistics for each offer.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>}

      {/* Footer */}
      <Box sx={{ padding: 8, textAlign: 'center' }}>
        <Typography variant="body1" color="black">
          Contact us at <a href="mailto:team@histomart.com">team@histomart.com</a>
        </Typography>
      </Box>
    </div>
  );
}

export default App;
