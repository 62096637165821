import { useMemo } from "react";
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';
import { Star, StarHalf, StarBorder, Check, Close } from "@mui/icons-material";
import { SellerLink } from '../components/SellerLink';
import { getLastPriceChange, fixProductHistory } from '../utils';

const styles = {
    cellTitle: {
        fontSize: "12px",
        width: "40%",
        maxWidth: "40%",
        verticalAlign: "top",
        textAlign: "left",
        padding: "8px",
    },
    cellValue: {
        fontSize: "12px",
        verticalAlign: "top",
        textAlign: "left",
        padding: "8px",
    },
    cellTitleCurrent: {
        width: "70%",
        maxWidth: "70%",
        textAlign: "left"
    }
};

export const ProductDetails = (props) => {

    const formatProductDimensions = () => {
        const { length = 0.0, width = 0.0, height = 0.0 } = props?.productData ?? {};

        const formatValue = (value) => (value > 0 ? value.toFixed(2) : "0.0");

        return `${formatValue(length)} x ${formatValue(width)} x ${formatValue(height)}`;
    };

    function calculateAverages() {
        const timeframes = [30, 90, 180, 365];
        const productHistory = fixProductHistory(props.productHistory);
        const now = new Date();

        const stats = {
            lowestBuyBox: Infinity,
            highestBuyBox: 0,
            lowestNew: Infinity,
            highestNew: 0,
            lowestWFS: Infinity,
            highestWFS: 0,
            lowestSF: Infinity,
            highestSF: 0,
        };

        timeframes.forEach(days => {
            stats[days] = {
                reviewCount: 0,
                buyBox: 0,
                lowestBuyBox: Infinity,
                highestBuyBox: 0,
                oosBuyBox: 0,
                new: 0,
                lowestNew: Infinity,
                highestNew: 0,
                oosNew: 0,
                wfs: 0,
                lowestWFS: Infinity,
                highestWFS: 0,
                sf: 0,
                lowestSF: Infinity,
                highestSF: 0,
                offerCount: 0,
                oosWalmart: 0,
                count: 0,
            };
        });

        function updateMinMax(stat, key, value) { // update all keys with lowest and highest
            if (value > 0) {
                stat[`highest${key}`] = Math.max(stat[`highest${key}`], value);
                stat[`lowest${key}`] = Math.min(stat[`lowest${key}`], value);
            }
        }

        productHistory.forEach(record => {
            const recordDate = new Date(record.date);
            const { review_count, current_price, current_offers } = record;

            timeframes.forEach(days => {
                const cutoffDate = new Date(now);
                cutoffDate.setDate(now.getDate() - days);

                if (recordDate >= cutoffDate) {
                    const statsRef = stats[days];

                    statsRef.reviewCount += review_count;
                    statsRef.new += current_price.price_lowest;
                    statsRef.buyBox += current_price.price;
                    statsRef.sf += current_price.price_lowest_sf;
                    statsRef.wfs += current_price.price_lowest_wfs;
                    statsRef.offerCount += current_offers.offer_count;
                    statsRef.count += 1;

                    if (current_price.price === -1) statsRef.oosBuyBox += 1;
                    if (current_price.price_lowest === -1) statsRef.oosNew += 1;
                    if (current_price.price_walmart === -1) statsRef.oosWalmart += 1;

                    updateMinMax(statsRef, "BuyBox", current_price.price);
                    updateMinMax(statsRef, "New", current_price.price_lowest);
                    updateMinMax(statsRef, "SF", current_price.price_lowest_sf);
                    updateMinMax(statsRef, "WFS", current_price.price_lowest_wfs);
                }
            });

            updateMinMax(stats, "BuyBox", current_price.price);
            updateMinMax(stats, "New", current_price.price_lowest);
            updateMinMax(stats, "SF", current_price.price_lowest_sf);
            updateMinMax(stats, "WFS", current_price.price_lowest_wfs);
        });

        function formatNumber(value) {
            return Number.isInteger(value) ? value : value.toFixed(2);
        }

        timeframes.forEach(days => {
            const statsRef = stats[days];
            const count = statsRef.count || 1;

            statsRef.reviewCount = (statsRef.reviewCount / count).toFixed(2);
            statsRef.new = (statsRef.new / count).toFixed(2);
            statsRef.buyBox = (statsRef.buyBox / count).toFixed(2);
            statsRef.wfs = (statsRef.wfs / count).toFixed(2);
            statsRef.sf = (statsRef.sf / count).toFixed(2);
            statsRef.offerCount = (statsRef.offerCount / count).toFixed(2);

            statsRef.oosBuyBox = formatNumber((statsRef.oosBuyBox / count) * 100) + "%";
            statsRef.oosNew = formatNumber((statsRef.oosNew / count) * 100) + "%";
            statsRef.oosWalmart = formatNumber((statsRef.oosWalmart / count) * 100) + "%";

            ["lowestBuyBox", "lowestNew", "lowestSF", "lowestWFS"].forEach(key => {
                if (statsRef[key] === Infinity) statsRef[key] = 0;
            });
        });

        ["lowestBuyBox", "lowestNew", "lowestSF", "lowestWFS"].forEach(key => {
            if (stats[key] === Infinity) stats[key] = 0;
        });
        return stats;
    }

    const getLowestWFSSeller = () => {
        if (!props?.productHistory?.length) return null;

        const lowestPriceEntry = props.productHistory.reduce((lowest, entry) => {
            const price = entry.current_price.price;
            return price > 0 && (price < lowest.current_price.price || lowest.current_price.price <= 0)
                ? entry
                : lowest;
        });

        if (lowestPriceEntry.current_price.price > 0) {
            return <SellerLink sellerName={lowestPriceEntry.current_seller.seller_display_name} sellerId={lowestPriceEntry.current_seller.sellerId} />
        }
        return null;
    };

    const lastProductHistory = props.productHistory[props.productHistory.length - 1];
    const averageStats = useMemo(() => calculateAverages(), [props.productHistory]);

    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
            <Box sx={{ width: "49%" }}>
                <Typography sx={{ display: "flex", padding: "4px", fontSize: "12px", fontWeight: "bold" }}>
                    Details
                </Typography>
                <TableContainer component={Paper}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Image</TableCell>
                                <TableCell sx={styles.cellValue}>
                                    <img src={props.productData?.thumbnail_url} alt="Product" style={{ width: "40px", height: "40px" }} />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Title</TableCell>
                                <TableCell sx={styles.cellValue}>{props.productData?.name}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>Sales Rank - Reference</TableCell>
                                <TableCell sx={styles.cellValue}>{props.productData?.name}</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Reviews - Rating</TableCell>
                                <TableCell sx={styles.cellValue}>
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        "& .MuiSvgIcon-root": {
                                            fontSize: { xs: "1rem", sm: "1.2rem" }
                                        }
                                    }}>
                                        {Array.from({ length: 5 }).map((star, index) => (
                                            lastProductHistory?.rating >= index + 1 ? <Star key={index} style={{ color: "#fcca03" }} /> :
                                                lastProductHistory?.rating > index ?
                                                    <StarHalf key={index} style={{ color: "#fcca03" }} /> :
                                                    <StarBorder key={index} style={{ color: "gray" }} />
                                        ))}
                                        <span style={{ marginLeft: "5px", color: "#16b0e9" }}>{lastProductHistory?.rating}</span>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Reviews - Review Count</TableCell>
                                <TableCell sx={styles.cellValue}>
                                    <span style={{ color: "#9cc71d" }}>{lastProductHistory?.review_count}</span>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Last Price Change</TableCell>
                                <TableCell sx={styles.cellValue}>{getLastPriceChange(props.productHistory)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Buy Box Seller</TableCell>
                                <TableCell sx={styles.cellValue}>
                                    <SellerLink
                                        sellerName={lastProductHistory?.current_seller?.seller_display_name}
                                        sellerId={lastProductHistory?.current_seller?.sellerId}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Buy Box Is WFS</TableCell>
                                <TableCell sx={styles.cellValue}>
                                    {lastProductHistory?.current_seller?.seller_wfs_enabled ?
                                        <Check sx={{ width: "16px", height: "16px" }} /> :
                                        <Close sx={{ width: "16px", height: "16px" }} />}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Lowest Price - Seller</TableCell>
                                <TableCell sx={styles.cellValue}>{getLowestWFSSeller()}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>FBA Pick&Pack Fee</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Referral Fee %</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Referral Fee based on current Buy Box price</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Tracking since</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productHistory[0]?.date.split("T")[0].replaceAll("-", "/")}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>Listed since</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Categories - Root</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productData?.category}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Categories - Sub</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productData?.sub_category}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>Categories - Tree</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Walmart Id</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productData?.item_id}</TableCell>
                            </TableRow>
                            {/* upc */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Barcode</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productData?.upc}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>Manufacturer</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Brand</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productData?.brand}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>Model</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Dimensions (L x W x H)</TableCell>
                                <TableCell sx={styles.cellValue}>({formatProductDimensions()})</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Weight (lb)</TableCell>
                                <TableCell sx={styles.cellValue}>{props?.productData?.weight > 0 ? props?.productData?.weight : 0}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell sx={styles.cellTitle}>Ingredients</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Active Ingredients</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Safety Warning</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Hazardous Materials</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Prime Eligible (Buy Box)</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Subscribe & Save</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={styles.cellTitle}>Subscribe & Save - Percentage</TableCell>
                                <TableCell sx={styles.cellValue}>Undefined</TableCell>
                            </TableRow> */}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{ width: '49%' }}>
                <Typography sx={{ display: "flex", padding: "4px", fontSize: "12px", fontWeight: "bold" }}>
                    Current & Average
                </Typography>
                <TableContainer component={Paper} style={{ width: "97%" }}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Reviews - Review Count - 90 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#9cc71d" }}>{averageStats["90"].reviewCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Reviews - Review Count - 180 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#9cc71d" }}>{averageStats["180"].reviewCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Reviews - Review Count - 365 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#9cc71d" }}>{averageStats["365"].reviewCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - Current</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>
                                    {lastProductHistory?.current_price?.price !== null
                                        ? `$${lastProductHistory?.current_price?.price.toFixed(2)}`
                                        : "N/A"
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - 90 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats["90"].buyBox}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - 180 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats["180"].buyBox}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - 365 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats["365"].buyBox}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - Lowest</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats.lowestBuyBox.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - Lowest 365 days</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats["365"].lowestBuyBox.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - Highest</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats.highestBuyBox.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - Highest 365 days</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>${averageStats["365"].highestBuyBox.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Buy Box - Stock</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "deeppink" }}>{lastProductHistory?.current_stock?.stock}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - Current</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>
                                    {lastProductHistory?.current_price?.price_lowest !== null
                                        ? `$${lastProductHistory?.current_price?.price_lowest.toFixed(2)}`
                                        : "N/A"
                                    }
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - 90 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats["90"].new}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - 180 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats["180"].new}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - 365 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats["365"].new}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - Lowest</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats.lowestNew.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - Lowest 365 days</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats["365"].lowestNew}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - Highest</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats.highestNew.toFixed(2)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New - Highest 365 days</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#8C8CFF" }}>${averageStats["365"].highestNew}</TableCell>
                            </TableRow>
                            {!props.isHalfData && <><TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - Current</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>
                                    {lastProductHistory?.current_price?.price_lowest_wfs !== null
                                        ? `$${lastProductHistory?.current_price?.price_lowest_wfs.toFixed(2)}`
                                        : "N/A"
                                    }
                                </TableCell>
                            </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - 90 days avg.</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats["90"].wfs}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - 180 days avg.</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats["180"].wfs}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - 365 days avg.</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats["365"].wfs}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - Lowest</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats.lowestWFS.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - Lowest 365 days</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats["365"].lowestWFS.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - Highest</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats.highestWFS.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - Highest 365 days</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>${averageStats["365"].highestWFS.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party WFS - Stock</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>{lastProductHistory?.current_stock?.stock_wfs ?? "N/A"}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - Current</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>
                                        {lastProductHistory?.current_price?.price_lowest_sf !== null
                                            ? `$${lastProductHistory?.current_price?.price_lowest_sf.toFixed(2)}`
                                            : "N/A"
                                        }
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - 90 days avg.</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats["90"].sf}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - 180 days avg.</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats["180"].sf}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - 365 days avg.</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats["365"].sf}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - Lowest</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats.lowestSF.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - Lowest 365 days</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats["365"].lowestSF.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - Highest</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats.highestSF.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - Highest 365 days</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>${averageStats["365"].highestSF.toFixed(2)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New, 3rd Party SF - Stock</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>{lastProductHistory?.current_stock?.stock_sf ?? "N/A"}</TableCell>
                                </TableRow></>}
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New Offer Count - Current</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#757575" }}>{lastProductHistory?.current_offers?.offer_count ?? "N/A"}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New Offer Count - 30 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#757575" }}>{averageStats["30"].offerCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New Offer Count - 90 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#757575" }}>{averageStats["90"].offerCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New Offer Count - 180 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#757575" }}>{averageStats["180"].offerCount}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>New Offer Count - 365 days avg.</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "#757575" }}>{averageStats["365"].offerCount}</TableCell>
                            </TableRow>
                            {!props.isHalfData && <><TableRow>
                                <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Count of retrieved live offers - New, WFS</TableCell>
                                <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "orange" }}>{lastProductHistory?.current_offers?.offer_count_wfs_sellers}</TableCell>
                            </TableRow>
                                <TableRow>
                                    <TableCell sx={{ ...styles.cellTitle, ...styles.cellTitleCurrent }}>Count of retrieved live offers - New, SF</TableCell>
                                    <TableCell sx={{ ...styles.cellValue, textAlign: "right", color: "darkcyan" }}>{lastProductHistory?.current_offers?.offer_count_sf_sellers}</TableCell>
                                </TableRow></>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box >
    )
}