import React, { useState, useEffect, useMemo } from "react";
import { Info, Check, Close, CalendarMonth } from "@mui/icons-material";
import { SellerLink } from "../components/SellerLink";
import { ExportToExcel } from "../components/ExportToExcel";
import { fixProductHistory, getLastUpdate } from "../utils";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
} from "@mui/material";

const styles = {
    cellTitle: {
        fontSize: "12px",
        whiteSpace: "nowrap",
        minWidth: "max-content",
        verticalAlign: "top",
        textAlign: "left",
        padding: "2px",
        paddingX: "10px",
        borderLeft: "1px solid lightgray"
    },
    cellValue: {
        fontSize: "12px",
        verticalAlign: "top",
        textAlign: "center",
        padding: "10px",
        whiteSpace: "nowrap",
        minWidth: "max-content",
    },
    tableHeader: {
        borderLeft: "none",
        borderBottom: "2px solid pink",
        boxShadow: '0px 4px 6px -2px pink'
    },
    tableContainer: {
        marginTop: "40px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            width: "12px",
            height: "12px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "lightgray",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#A9A9A9",
            borderRadius: "10px",
        },
    },
    info: {
        fontSize: "12px",
        textTransform: "none",
        color: "grey.800",
        padding: 0,
        margin: 0,
    }
};

const columnsData = [
    { key: "wonPercent", label: "% Won", align: "right", sortable: true, format: (value) => `${value}%` },
    { key: "avgPrice", label: "Avg. Price", align: "right", sortable: true, format: (value) => `$${value}` },
    { key: "avgNewCountOffer", label: "Avg. New Offer Count", align: "right", sortable: true },
    { key: "seller", label: "Name", align: "left", sortable: false, format: ({ sellerName, sellerId }) => <SellerLink sellerName={sellerName} sellerId={sellerId} /> },
    { key: "wfs", label: "WFS", align: "center", sortable: false, isBoolean: true },
    { key: "ratingAvg", label: "Rating", align: "right", sortable: true, format: (value) => `${((value / 5) * 100).toFixed(2)}%` },
    { key: "reviews", label: "Reviews", align: "right", sortable: true },
    { key: "lastWon", label: "Last won", align: "left", sortable: true, format: (value) => `${new Date(value).toLocaleDateString("en-GB")} ${new Date(value).toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })}` },
    { key: "lastWon", label: "Last won (ago)", align: "left", sortable: false, format: (value, isWinner) => isWinner ? "just now" : getLastUpdate(new Date(value).getTime()) },
];

const graphOptions = ["30", "60", "90", "180", "365", "All"];

export const BuyBoxStatistics = (props) => {
    const allSellers = useMemo(() => extractSellersData(), [props.productHistory]);

    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [selectedRange, setSelectedRange] = useState("All");
    const [sellersData, setSellersData] = useState(filterDataByRange);

    useEffect(() => {
        const filteredSellers = filterDataByRange();
        setSellersData(filteredSellers);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRange]);

    function extractSellersData() {
        const productHistory = fixProductHistory(props.productHistory); // fixing gaps in data and taking oldest record in each day

        const sellers = productHistory.reduce((acc, record) => {
            const sellerName = record?.current_seller?.seller_display_name;

            if (!acc[sellerName]) {
                acc[sellerName] = {
                    seller: {
                        sellerName: sellerName,
                        sellerId: record?.current_seller?.sellerId,
                    },
                    stock: record?.current_stock?.stock,
                    wfs: record?.current_seller?.seller_wfs_enabled,
                    reviews: record?.current_seller?.seller_review_count,
                    ratingAvg: record?.current_seller?.seller_avg_rating,
                    lastWon: record?.date,
                    isBuyBoxWinner: false,
                    wonCount: 0,
                    data: []
                };
            }

            acc[sellerName].data.push({
                price: record?.current_price?.price,
                newCountOffer: record?.current_offers?.offer_count,
                date: record.date
            });

            acc[sellerName] = {
                ...acc[sellerName],
                wfs: record?.current_seller?.seller_wfs_enabled,
                stock: record?.current_stock?.stock,
                reviews: record?.current_seller?.seller_review_count,
                ratingAvg: record?.current_seller?.seller_avg_rating,
                wonCount: acc[sellerName].wonCount + 1,
                lastWon: record?.date
            }
            return acc;
        }, {});

        if (productHistory.length > 0) {
            const lastProductHistory = productHistory[productHistory.length - 1]
            const buyBoxWinner = lastProductHistory?.current_seller?.seller_display_name;

            if (sellers[buyBoxWinner]) {
                sellers[buyBoxWinner].isBuyBoxWinner = true;
            }
        }
        return sellers;
    }

    function filterDataByRange() {
        const now = new Date();
        let totalFilteredRecords = 0;

        const filteredData = Object.values(allSellers).reduce((acc, seller) => {
            let totalPrice = 0;
            let totalNewCountOffer = 0;
            let filteredItems = [];
            let filteredWonCount = 0;

            for (const item of seller.data) {
                const itemDate = new Date(item.date);
                const diffInDays = Math.floor((now - itemDate) / (1000 * 60 * 60 * 24));

                if (selectedRange === "All" || diffInDays <= +selectedRange) {
                    filteredItems.push(item);
                    totalPrice += item.price;
                    totalNewCountOffer += item.newCountOffer;
                    filteredWonCount++;
                    totalFilteredRecords++;
                }
            }

            if (filteredItems.length > 0) {
                acc.push({
                    ...seller,
                    data: filteredItems,
                    avgPrice: filteredItems.length ? (totalPrice / filteredItems.length).toFixed(2) : 0,
                    avgNewCountOffer: filteredItems.length ? (totalNewCountOffer / filteredItems.length).toFixed(2) : 0,
                    filteredWonCount
                });
            }
            return acc;
        }, []);

        filteredData.forEach(seller => {
            seller.wonPercent = totalFilteredRecords > 0 ? ((seller.filteredWonCount / totalFilteredRecords) * 100).toFixed(2) : 0;
        });
        return filteredData;
    }

    const handleSort = (key) => {
        let direction = "asc";

        if (sortConfig.key === key) {
            if (sortConfig.direction === "asc") {
                direction = "desc";
            } else if (sortConfig.direction === "desc") {
                direction = null;
                key = null;
            }
        }

        setSortConfig({ key, direction });

        const sellers = filterDataByRange();

        if (direction === null) {
            setSellersData(sellers);
        } else {
            const sorted = [...sellers].sort((a, b) => {
                switch (key) {
                    case "lastWon":
                        const dateA = new Date(a[key]);
                        const dateB = new Date(b[key]);

                        return direction === "asc" ?
                            dateB - dateA :
                            dateA - dateB;
                    default:
                        return direction === "asc" ?
                            b[key] - a[key] :
                            a[key] - b[key];

                }
            });
            setSellersData(sorted);
        }
    };

    function formatExportData() {
        return sellersData.map((row) => {
            return columnsData
                .filter(col => col.label !== "Last won (ago)")
                .reduce((acc, col) => {
                    const value = row[col.key];

                    if (col.isBoolean) {
                        acc[col.label] = value ? "Yes" : "No";
                    } else if (col.key === "seller") {
                        acc[col.label] = value.sellerName;
                    } else if (col.format) {
                        acc[col.label] = col.format(value, row.isBuyBoxWinner);
                    } else {
                        acc[col.label] = value;
                    }
                    return acc;
                }, {});
        });
    }

    return (
        <Box sx={{ width: "100%", height: "100%" }}>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start", width: "100%", gap: "1px", mb: "20px" }}>
                <span style={{ fontSize: "14px" }}>The Buy Box can be shared between multiple sellers. The following table gives an overview of the percentage each seller held the <strong>New</strong> Buy Box.</span>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: "20px", gap: "5px" }}>
                <ExportToExcel fileName="Buy_Box_Statistics" data={formatExportData()} />
                <Box sx={{ ...styles.info, display: "flex", alignItems: "center", gap: "2px", textAlign: "center" }}>
                    <Info sx={{ fontSize: "18px" }} />
                    <span style={{ display: "flex", alignItems: "cetner", color: "#757575" }}>Number of results: {sellersData.length}</span>
                </Box>
                <Box sx={{ ...styles.info, display: "flex", alignItems: "center", gap: "2px", textAlign: "center" }}>
                    <CalendarMonth sx={{ fontSize: "18px" }} />
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                        flexWrap: "wrap"
                    }}>
                        <span style={{ fontWeight: "semibold" }}>Graph range in days:</span>
                        {graphOptions.map((option) => (
                            <div
                                key={option}
                                onClick={() => setSelectedRange(option)}
                                style={{
                                    color: selectedRange === option ? "black" : "darkgray",
                                    cursor: "pointer",
                                    fontSize: "12px",
                                }}
                            >
                                {option}
                            </div>
                        ))}
                    </div>
                </Box>
            </Box>
            <TableContainer component={Paper} sx={styles.tableContainer}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...styles.cellTitle, borderLeft: "none" }} colSpan={4}></TableCell>
                            <TableCell sx={{ ...styles.cellTitle, ...styles.tableHeader }} colSpan={4}>Seller</TableCell>
                        </TableRow>
                        <TableRow>
                            {columnsData.map((col, index) => (
                                <TableCell key={index} sx={styles.cellTitle} align={col.align}>
                                    {col.sortable ? (
                                        <TableSortLabel
                                            active={sortConfig.key === col.key}
                                            direction={sortConfig.direction === "asc" ? "asc" : "desc"}
                                            onClick={() => handleSort(col.key)}
                                        >
                                            {col.label}
                                        </TableSortLabel>
                                    ) : (
                                        col.label
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sellersData.map((row, index) => (
                            <TableRow key={index}>
                                {columnsData.map((col, index) => (
                                    <TableCell key={col.key + index} sx={{ ...styles.cellValue, textAlign: col.align }}>
                                        {col.isBoolean ? (row[col.key] ? <Check /> : <Close />) : col.format
                                            ? col.format(row[col.key], row.isBuyBoxWinner)
                                            : row[col.key]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box >
    );
}
