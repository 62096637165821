import { OpenInNew } from "@mui/icons-material";

export const SellerLink = ({ sellerName, sellerId }) => {
    if (!sellerName) return null;

    if (!sellerId && sellerId !== 0) {
        return (
            <span style={{ display: "flex", alignItems: "center" }}>
                {sellerName}
            </span>
        );
    }

    return (
        <a
            style={{
                color: "deeppink",
                display: "flex",
                alignItems: "center",
                gap: "3px"
            }}
            href={`https://www.walmart.com/seller/${sellerId}`}
            target="_blank"
            rel="noopener noreferrer"
        >
            <span style={{ display: "flex", alignItems: "center" }}>
                {sellerName}
            </span>
            <span style={{ display: "flex", alignItems: "center" }}>
                <OpenInNew sx={{ width: "14px", height: "14px" }} />
            </span>
        </a>
    );
};