import React from 'react';
import { Container, Typography, Button, Box, Grid, Paper } from '@mui/material';
import Navbar from './Navbar';
import { getUser, isUserAuthenticated } from './utils/authService';

const PricingPage = () => {
    const [active, setActive] = React.useState(false);

    React.useEffect(() => {
        const getAccountDetails = async () => {
            fetch(
                `https://server.nepeto.com/hist_get_sub_details/${getUser()}/`,
                {
                    mode: "cors",
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setActive(data.active);
                })
                .catch(() => {
                });
        };
        getAccountDetails();
    }, []);

    return (
        <>
            <Navbar />
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    minHeight: '75vh',
                }}
            >
                <Typography variant="h2" color="primary" gutterBottom sx={{ fontWeight: 'bold', color: "#2ECC40" }}>
                    Histomart
                </Typography>

                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={4}
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: 3,
                                textAlign: 'center',
                                padding: 4,
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h5" color="textPrimary" sx={{ fontWeight: 'bold' }}>
                                Monthly Plan
                            </Typography>
                            <Typography variant="h4" color="success.main" sx={{ fontWeight: 'bold', marginTop: 2, color: "#2ECC40" }}>
                                $18
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                                Access all features, all historical data, and premium support.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2, fontWeight: "600" }}>
                                7 Days Free Trial
                            </Typography>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={active}
                                sx={{
                                    marginTop: 3,
                                    padding: '10px 24px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    borderRadius: '30px',
                                    backgroundColor: "#2ECC40"
                                }}
                                onClick={() => {
                                    if (isUserAuthenticated()) {
                                        window.location.href = `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=108717&page-template=19169&language=en&currency=USD&billing-email=${getUser()}`;
                                    } else {
                                        window.location.href = "/user"
                                    }
                                }}
                            >
                                {active ? "Already Subscribed" : "Subscribe Now"}
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Paper
                            elevation={4}
                            sx={{
                                backgroundColor: '#fff',
                                borderRadius: 3,
                                textAlign: 'center',
                                padding: 4,
                                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <Typography variant="h5" color="textPrimary" sx={{ fontWeight: 'bold' }}>
                                Annual Plan
                            </Typography>
                            <Typography variant="h4" color="success.main" sx={{ fontWeight: 'bold', marginTop: 2, color: "#2ECC40" }}>
                                $180
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2 }}>
                                Access all features, all historical data, and premium support.
                            </Typography>
                            <Typography variant="body1" color="textSecondary" sx={{ marginTop: 2, fontWeight: "600" }}>
                                7 Days Free Trial
                            </Typography>
                            <Button
                                variant="contained"
                                color="success"
                                disabled={active}
                                sx={{
                                    marginTop: 3,
                                    padding: '10px 24px',
                                    fontSize: '16px',
                                    fontWeight: 'bold',
                                    textTransform: 'none',
                                    borderRadius: '30px',
                                    backgroundColor: "#2ECC40"
                                }}
                                onClick={() => {
                                    if (isUserAuthenticated()) {
                                        window.location.href = `https://store.payproglobal.com/checkout?products%5B1%5D%5Bid%5D=108808&page-template=19169&language=en&currency=USD&billing-email=${getUser()}`;
                                    } else {
                                        window.location.href = "/user"
                                    }
                                }}
                            >
                                {active ? "Already Subscribed" : "Subscribe Now"}
                            </Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <img src="https://www.nepeto.com/assets/PIONEERS.png" onClick={() => window.open("https://www.getmarter.com/pioneers-dashboard")} style={{ width: "100%", borderRadius: "20px", boxShadow: "0px 0px 5px 0px green", cursor: "pointer", margin: "auto", display: "block" }} />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default PricingPage;
