import { useState, createElement, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, Button, LinearProgress, Tooltip } from "@mui/material";
import { Star, StarHalf, StarBorder } from "@mui/icons-material";
import { WalmartPriceHistory } from './tables/WalmartPriceHistory.js';
import { getLastPriceChange, getLastUpdate } from "./utils.js";
import { Data } from './tables/Data.js';
import Navbar from '../Navbar.js';
import { getUser, signOut } from '../utils/authService.js';

const styles = {
    page: {
        padding: "1vh 0vh",
        minHeight: "100vh",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        px: { xs: 1, sm: 2 },
        overflow: "auto",
        textAlign: "center"
    },
    mainContainer: {
        backgroundColor: "white",
        position: "relative",
        width: { xs: "100%", sm: "95%" },
        display: "flex",
        height: "auto",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        borderRadius: { xs: "5px", sm: "10px" },
        minHeight: "max-content",
        flexGrow: 1,
        overflow: "auto",
        textAlign: "center"
    },
    sideBar: {
        width: { xs: "60px", sm: "80px", md: "120px" },
        minWidth: { xs: "60px", sm: "80px", md: "120px" },
        height: "auto",
        minHeight: "100%",
        borderRight: "1px solid rgba(128, 128, 128, 0.5)",
        padding: { xs: "5px", sm: "10px" },
        paddingY: { xs: "15px", sm: "25px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: "10px", sm: "15px" },
        overflowY: "auto",
        backgroundColor: "white"
    },
    content: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        gap: "8px",
        height: "auto",
        width: "100%",
        alignItems: "start",
        flexGrow: 1,
        backgroundColor: "white"
    },
    titleSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },
    titleBox: {
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        gap: { xs: "5px", sm: "10px" },
        width: "100%"
    },
    ratingBox: {
        display: "flex",
        alignItems: "center",
        gap: "2px",
        "& .MuiSvgIcon-root": {
            fontSize: { xs: "1rem", sm: "1.2rem" }
        }
    },
    priceBox: {
        marginTop: { xs: "5px", sm: "10px" },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "start", sm: "center" },
        gap: { xs: "15px", sm: "20px" },
        fontSize: { xs: "0.875rem", sm: "1rem" }
    },
    halfDataBox: {
        position: { xs: "relative", sm: "relative", md: "relative", lg: "absolute" },
        top: { md: 0, lg: "5%" },
        right: { md: "2%" },
        textAlign: "center",
        padding: 2,
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        boxShadow: 3,
        maxWidth: { xs: "90%", sm: 320, md: 300 },
        margin: "0 auto",
        width: "100%",
    }
}

const tabs = [
    { name: "Walmart Price History", component: WalmartPriceHistory },
    { name: "Data", component: Data },
];

export default function KpPage(props) {
    const [data, setData] = useState({
        productData: {},
        productHistory: []
    });

    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const [blocked, setBlocked] = useState(false);

    const [isMobile, setIsMobile] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const item_id = queryParams.get("item_id");

    const checkIfMobile = (windowMatchMedia) => {
        if (windowMatchMedia.matches) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        const windowMatchMedia = window.matchMedia("(max-width: 700px)");
        checkIfMobile(windowMatchMedia);

        const listener = (event) => checkIfMobile(event);

        windowMatchMedia.addEventListener("change", listener);

        return () => {
            windowMatchMedia.removeEventListener("change", listener);
        };
    }, []);

    // 12020208626 - no data
    useEffect(() => { // set error handling to show incorrect path/failed data fetch
        const t = (Math.floor(Date.now() / 1000));

        if (!item_id) {
            setFailed(true);
            return;
        }

        async function loadData() {
            setLoading(true);
            let hmac = localStorage.getItem("hmac")
            let hmrc = localStorage.getItem("_hmrc")
            try {
                let response = await fetch(`https://server.histomart.com/mw/get?i=${item_id}&z=${t}&e=${getUser()}`, {
                    mode: "cors",
                    headers: { "Authorization": `Bearer ${hmac}` }
                });

                // Check if response status is 401 (unauthorized)
                if (response.status !== 200) {
                    // Attempt to renew the session by sending a POST request
                    const renewResponse = await fetch("https://server.histomart.com/mw/renew", {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            'Content-Type': 'application/json',
                            "Authorization": `Bearer ${hmrc}`
                        }
                    });

                    // If the renew request fails (status not 200), block the user
                    if (!renewResponse.ok) {
                        console.error("BLOCKED");
                        setBlocked(true);
                        setLoading(false);
                        return;
                    } else {
                        const data = await renewResponse.json();
                        localStorage.setItem("hmac", data.hmac)
                        // Retry the original GET request after session renewal
                        response = await fetch(`https://server.histomart.com/mw/get?i=${item_id}&z=${t}&e=${getUser()}`, {
                            mode: "cors",
                            headers: { "Authorization": `Bearer ${data.hmac}` }
                        });
                        if (response.status === 200) {
                            setBlocked(false);
                        }
                    }

                }


                const data = await response.json();

                if (data === "No permissions") {
                    window.location.href = "/pricing"
                }

                // Count if data is mainly full or half
                let isHalf = 0;
                let isFull = 0;
                data.histories_js.forEach(obj => {
                    if ((obj?.current_offers?.offer_count || 0) > JSON.parse(obj?.offers || "[]").length) {
                        isHalf++;
                    } else {
                        isFull++;
                    }
                });

                const result = data.histories_js.filter(obj => {
                    const offerCount = obj?.current_offers?.offer_count || 0;
                    const offersLength = JSON.parse(obj?.offers || "[]").length;

                    return isHalf > isFull ? offerCount > offersLength : offerCount <= offersLength;
                });

                data.histories_js = result


                const history = data.histories_js.sort((a, b) => new Date(a.date) - new Date(b.date))

                setData({
                    productData: data.prd,
                    productHistory: history,
                    isHalfData: isHalf > isFull
                });
            } catch (error) {
                console.error("Error loading data:", error);
                setFailed(true);
            }
            setLoading(false);
        }

        loadData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    if (blocked) {
        signOut();
        window.location.href = "/user";
    }

    if (loading) {
        return <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}><img style={{ width: "50%" }} src="/histomart.png" /><br />Loading...</div>;
    }

    if (failed) {
        return <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", textAlign: "center" }}>Cannot find data for this product<br /><a href="/">Go back</a></div>;
    }

    const lastProductHistory = data.productHistory[data.productHistory.length - 1];

    return (<>
        <Navbar />
        <Box sx={styles.page} className='bg'>
            <Box sx={styles.mainContainer}>
                {!isMobile &&
                    <Box sx={styles.sideBar}>
                        <img onClick={() => window.open("https://www.walmart.com/ip/" + item_id)} src={data?.productData?.thumbnail_url || ""} alt="Product" style={{
                            width: "100%",
                            aspectRatio: "1/1",
                            objectFit: "cover",
                            cursor: "pointer"
                        }} />
                    </Box>
                }
                <Box sx={styles.content}>
                    <Box sx={styles.titleSection}>
                        <Box sx={styles.titleBox}>
                            <Box sx={{ display: "flex", gap: "5px" }}>
                                {isMobile && (
                                    <img onClick={() => window.open("https://www.walmart.com/ip/" + item_id)} src={data?.productData?.thumbnail_url || ""} alt="Product" style={{
                                        width: "45px",
                                        aspectRatio: "1/1",
                                        objectFit: "cover",
                                        cursor: "pointer"
                                    }} />
                                )}
                                <Typography onClick={() => window.open("https://www.walmart.com/ip/" + item_id)} sx={{
                                    fontWeight: "bold",
                                    fontSize: { xs: "1rem", sm: "1.2rem", md: "1.5rem" },
                                    lineHeight: { xs: "1.2rem", sm: "1.4rem", md: "1.8rem" },
                                    textAlign: "start",
                                    cursor: "pointer"
                                }}>
                                    {data.productData.name}
                                </Typography>
                            </Box>
                            <Box sx={styles.ratingBox}>
                                {Array.from({ length: 5 }).map((_, index) => (
                                    lastProductHistory?.rating >= index + 1 ? <Star key={index} style={{ color: "#fcca03" }} /> :
                                        lastProductHistory?.rating > index ?
                                            <StarHalf key={index} style={{ color: "#fcca03" }} /> :
                                            <StarBorder key={index} style={{ color: "gray" }} />
                                ))}
                                <span style={{ marginLeft: "5px", color: "#f8bd07" }}>{lastProductHistory?.rating}</span>
                                <span style={{ marginLeft: "5px", color: "gray" }}>({lastProductHistory?.review_count} reviews)</span>
                            </Box>
                        </Box>
                        <Typography sx={{ fontSize: { xs: "0.875rem", sm: "1rem" } }}>
                            by {data.productData.brand}
                        </Typography>
                    </Box>
                    <Box sx={styles.priceBox}>
                        <Typography sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            <span>Buy Box: <span style={{ fontSize: "18px", color: "deeppink", textWrap: "nowrap" }}>$ {lastProductHistory?.current_price?.price.toFixed(2)}</span></span>
                            <span>New: <span style={{ fontSize: "18px", color: "#8C8CFF", textWrap: "nowrap" }}>$ {lastProductHistory?.current_price?.price_lowest.toFixed(2)}</span></span>
                        </Typography>
                        <Typography sx={{ fontStyle: "italic", color: "gray" }}>
                            (last update: {getLastUpdate(lastProductHistory?.date)}, last price change: {getLastPriceChange(data.productHistory)})
                        </Typography>
                    </Box>
                    <Box sx={{
                        "& .MuiTab-root": {
                            fontSize: { xs: "0.875rem", sm: "1rem" },
                            minHeight: { xs: "40px", sm: "48px" },
                            px: { xs: 2, sm: 3 }
                        }
                    }}>
                        <Tabs
                            value={tab}
                            onChange={handleTabChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            sx={{
                                borderBottom: "2px solid #ccc",
                                minWidth: "fit-content",
                                '& .MuiTab-root': {
                                    borderRadius: "5px 5px 0 0",
                                    textTransform: "none",
                                    minWidth: 0,
                                    '&:hover': {
                                        color: "gray",
                                    },
                                },
                            }}
                        >
                            {tabs.map((tabInfo, index) => (
                                <Tab key={index} label={tabInfo.name} />
                            ))}
                        </Tabs>
                    </Box>
                    <Box sx={{ width: "100%", height: "100%", overflowY: "visible", overflow: "hidden", scrollbarWidth: "thin" }}>
                        {tabs[tab]?.component && createElement(tabs[tab].component, data)}
                    </Box>
                </Box>
            </Box>
        </Box>
    </>
    );
}