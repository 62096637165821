import { useState, createElement } from "react";
import { Box, Tabs, Tab } from "@mui/material";
import { ProductDetails } from "./ProductDetails";
import { BuyBoxStatistics } from "./BuyBoxStatistics"
import { Offers } from "./Offers";

const tabs = [
    { name: "Product Details", component: ProductDetails },
    { name: "Offers", component: Offers },
    { name: "Buy Box Statistics", component: BuyBoxStatistics }
];

export const Data = (props) => {
    const [tab, setTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    };

    return (
        <Box style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{
                "& .MuiTab-root": {
                    fontSize: { xs: "0.875rem", sm: "1rem" },
                    minHeight: { xs: "40px", sm: "48px" },
                    px: { xs: 2, sm: 3 },
                }
            }}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                        borderBottom: "2px solid #ccc",
                        minWidth: "fit-content",
                        maxWidth: "fit-content",
                        '& .MuiTab-root': {
                            borderRadius: "5px 5px 0 0",
                            textTransform: "none",
                            fontSize: "13px",
                            minWidth: 0,
                            '&:hover': {
                                color: "gray",
                            },
                        },
                    }}
                >
                    {tabs.map((tabInfo, index) => (
                        <Tab key={index} label={tabInfo.name} />
                    ))}
                </Tabs>
            </Box>
            <Box sx={{ width: "100%", height: "100%", overflowY: "auto", scrollbarWidth: "thin", padding: "10px", paddingTop: "10px" }}>
                {tabs[tab]?.component && createElement(tabs[tab].component, props)}
            </Box>
        </Box>
    );
}