export let isLoggedIn = false;
export async function issueFetch(email) {
    async function getWebGLFingerprint() {
        const canvas = document.createElement('canvas');
        const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
        if (!gl) return { vendor: "No WebGL", renderer: "No WebGL" };

        const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
        const vendor = debugInfo ? gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) : "Unknown";
        const renderer = debugInfo ? gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : "Unknown";

        async function sha256(str) {
            const encoder = new TextEncoder();
            const data = encoder.encode(str);
            const hashBuffer = await crypto.subtle.digest("SHA-256", data);
            return Array.from(new Uint8Array(hashBuffer))
                .map(b => b.toString(16).padStart(2, "0"))
                .join("");
        }

        const fingerprint = vendor + " " + renderer;
        const fingerprintHash = await sha256(fingerprint);


        return { vendor, renderer, fingerprintHash, email };
    }

    // Get fingerprint details
    const fingerprintData = await getWebGLFingerprint();
    // Sending the data to your backend with cookies
    const body = {
        "renderer": fingerprintData.renderer,
        "vendor": fingerprintData.vendor,
        "mail": fingerprintData.email,
        "fp": fingerprintData.fingerprintHash
    };

    const response = await fetch('https://server.histomart.com/mw/issue', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    });

    // Handling the response from backend
    const data = await response.json();



    // Set isLoggedIn to true if the response is successful
    if (response.ok) {
        isLoggedIn = true;

        // Set up a 5-minute interval for sending the renew request
    }
    return data;
}