import '../App.css';
import { Typography, Container, Grid, Box, Card, CardContent, CircularProgress } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the carousel styles
import Navbar from '../Navbar';
import { isUserAuthenticated, getUser } from '../utils/authService';
import { useState, useEffect } from 'react';

// Async request function to fetch data
async function request(url) {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    mode: "cors",
  });

  return response.json(); // Make sure to return the JSON response
}

const ProductTile = ({ product }) => {
  return (
    <a href={`/kp?item_id=${product.item_id}`} className="search-product-tile">
      <img src={product.image} alt={product.name} className="search-product-image" />
      <h3 className="search-product-title">{product.title}</h3>
      <p className="search-product-price">{product.price}</p>
    </a>
  );
};



const SearchProduct = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);  // Add loading state
  const [error, setError] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const query = queryParams.get("q");

  useEffect(() => {
    const fetchData = async () => {
      let response = null;
      let url = null;

      try {
        setLoading(true);
        setError(null);

        if (query?.length > 11 && !(/[A-Za-z]/.test(query))) {
          url = `https://server.getmarter.com/get_options_from_barcode/${query}/friedman.ido@gmail.com/`;
          response = await request(url);
        }
        else if (/[A-Za-z]/.test(query)) {
          url = `https://server.getmarter.com/get_options_from_walmart_search_box/${query}/friedman.ido@gmail.com/`;
          response = await request(url);
        }
        else {
          setLoading(false);
          window.location.replace(`/kp/?item_id=${query}`);
          return;
        }

        if (response && Array.isArray(response)) {
          setProducts(response);
        } else {
          console.error("Unexpected response format", response);
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try different search.");
        setProducts([]);
      } finally {
        setLoading(false);
      }
    };

    if (query) {
      fetchData();
    }
  }, [query]);

  return (
    <div>
      {loading ? (
        <div className="search-loading-container">
          <h2>Searching for {query}...</h2>
          <CircularProgress size={50} />
        </div>
      ) : (
        <h1 id="search-title">Search results for {query}</h1>
      )}
      <div className="search-product-list">
        {products.length > 0 ? (
          products.map((product) => (
            <ProductTile key={product.item_id} product={product} />
          ))
        ) : (
          !loading && !error && <p>No products found</p>
        )}
      </div>
    </div>
  );
};




function SearchPage() {
  return (
    <div className='bg'>
      <Navbar />

      {isUserAuthenticated() ?
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          minHeight="95vh"
          bgcolor="#E8F5E9"
        >
          <SearchProduct /> {/* Render the SearchProduct component */}
        </Box>
        : <>
          <Container sx={{ paddingTop: 6, textAlign: 'center' }}>
            <Typography variant="h3" sx={{ color: "#2ECC40", fontWeight: 'bold', marginBottom: 3 }}>
              Historical Data for Walmart
            </Typography>
            <Typography variant="h6" sx={{ marginBottom: 4 }}>
              Discover trends, analyze insights, and make informed decisions with historical data.
            </Typography>
          </Container>

          <Container sx={{ paddingY: 3 }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Carousel thumbWidth={"19%"} infiniteLoop={true} autoPlay={true} interval={4000}>
                  <div>
                    <img src="s1.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s2.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s3.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s4.png" alt="Screenshot" className="carImg" />
                  </div>
                  <div>
                    <img src="s5.png" alt="Screenshot" className="carImg" />
                  </div>
                </Carousel>
              </Grid>
            </Grid>
          </Container>

          <Container sx={{ paddingY: 6 }}>
            <Typography variant="h4" sx={{ textAlign: 'center', marginBottom: 4 }}>
              Featured Data
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>History Charts</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Price History, Stock History, Offer Count History and Badges History.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Product Data</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Key information and statistics, OOS status, average pricing, lowest pricing, and more.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Offers Data</Typography>
                    <Typography variant="body2" color="text.secondary">
                      Offers table, price history, stock history, and Buy Box winning statistics for each offer.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </>}

      {/* Footer */}
      <Box sx={{ padding: 8, textAlign: 'center' }}>
        <Typography variant="body1" color="black">
          Contact us at <a href="mailto:team@histomart.com">team@histomart.com</a>
        </Typography>
      </Box>
    </div>
  );
}

export default SearchPage;
