import { formatDistance } from "date-fns";

export const safeParseInt = (value) => {
    if (value === true) return 1;
    if (value === false) return 0;
    const num = parseInt(value, 10);
    return isNaN(num) ? -1 : num;
};

export const safeParseFloat = (value) => {
    if (value === true) return 1;
    if (value === false) return 0;
    const num = parseFloat(value, 10);
    return isNaN(num) ? -1 : num;
};

export const getLastPriceChange = (productHistory) => {
    if (!productHistory?.length) return "No price changes available";

    const sortedHistory = productHistory
        .map(entry => ({
            ...entry,
            date: new Date(entry?.date)
        }))
        .sort((a, b) => b.date - a.date);

    let previousPrice = null;

    for (let entry of sortedHistory) {
        const currentPrice = entry?.current_price?.price;

        if (previousPrice !== null && currentPrice !== previousPrice) {
            return formatDistance(entry?.date, Date.now(), { addSuffix: true });
        }
        previousPrice = currentPrice;
    }
    return "No valid price change found";
};

export const getLastUpdate = (timestamp) => {
    if (!timestamp) return;

    return formatDistance(timestamp, Date.now(), {
        addSuffix: true,
    });
}

export const fixProductHistory = (records) => {
    if (!records) return [];

    const dailyRecords = {};

    records.forEach(record => {
        const dateKey = record.date.split("T")[0];
        if (!dailyRecords[dateKey] || new Date(record.date) > new Date(dailyRecords[dateKey].date)) {
            dailyRecords[dateKey] = record;
        }
    });

    let sortedDates = Object.keys(dailyRecords).sort();
    let finalRecords = [];

    for (let i = 0; i < sortedDates.length; i++) {
        finalRecords.push(dailyRecords[sortedDates[i]]);

        if (i < sortedDates.length - 1) {
            let currentDate = new Date(sortedDates[i]);
            let nextDate = new Date(sortedDates[i + 1]);

            while ((currentDate = new Date(currentDate.getTime() + 86400000)) < nextDate) {
                let duplicatedRecord = { ...dailyRecords[sortedDates[i]], date: currentDate.toISOString() };
                finalRecords.push(duplicatedRecord);
            }
        }
    }
    return finalRecords;
}